import React from "react";
import { Button, Menu } from "semantic-ui-react";

type Props = {
	onHowToPlayClick: () => void;
	showHowToPlay: boolean;
};
export const NavBar: React.FC<Props> = (props) => {
	return (
		<Menu secondary>
			<Menu.Item>
				<h1>Is it Hate?</h1>
			</Menu.Item>
			<Menu.Menu position="right">
				<Menu.Item>
					{props.showHowToPlay ? (
						<Button basic onClick={props.onHowToPlayClick}>
							Hate Speech Definition
						</Button>
					) : (
						""
					)}
				</Menu.Item>
			</Menu.Menu>
		</Menu>
	);
};

NavBar.displayName = "NavBar";
