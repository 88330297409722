import React, { useState, useEffect } from "react";
import Div100vh from "react-div-100vh";
import { Loader, Modal } from "semantic-ui-react";
import { Game } from "./game/Game";
import styles from "./main.scss";
import { Disclaimer } from "./intro/Disclaimer";
import { NavBar } from "./header/NavBar";
import { HowToPlay } from "./intro/HowToPlay";

export const Main: React.FC = () => {
	const [acceptedDisclaimer, setAcceptedDisclaimer] = useState(false);
	const [uid, setUid] = useState<string>();

	const [showingInstructions, setShowingInstructions] = useState(true);

	useEffect(() => {
		if (!acceptedDisclaimer) {
			return;
		}

		window.firebase
			.auth()
			.signInAnonymously()
			.then(
				(cred) => {
					if (cred.user == null) {
						throw new Error("Error getting a user ID from firebase");
					}
					setUid(cred.user.uid);
				},
				(reason) => {
					throw reason;
				},
			);
	}, [acceptedDisclaimer]);

	let contentElement;
	if (!acceptedDisclaimer) {
		contentElement = (
			<Disclaimer acceptDisclaimer={() => setAcceptedDisclaimer(true)} />
		);
	} else if (uid == null) {
		contentElement = <Loader active inline="centered" />;
	} else {
		contentElement = <Game />;
	}

	return (
		<Div100vh className={styles.app}>
			<header>
				<NavBar
					showHowToPlay={acceptedDisclaimer}
					onHowToPlayClick={() => setShowingInstructions(true)}
				></NavBar>
			</header>
			<main className={styles.content}>{contentElement}</main>
			<Modal
				size="large"
				open={acceptedDisclaimer && showingInstructions}
				onClose={() => setShowingInstructions(false)}
			>
				<HowToPlay done={() => setShowingInstructions(false)}></HowToPlay>
			</Modal>
		</Div100vh>
	);
};
Main.displayName = "Main";
