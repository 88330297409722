import React from "react";
import ReactDOM from "react-dom";

import * as Sentry from "@sentry/browser";

import { ErrorBoundary } from "./error/ErrorBoundary";
import { SentryProvider } from "./error/SentryProvider";
import { Main } from "./Main";

Sentry.init({
	dsn:
		"https://822cbef3394444f681620897145f5382@o255751.ingest.sentry.io/5242322",
});

export const App: React.FC = () => {
	return (
		<SentryProvider>
			<Main />
		</SentryProvider>
	);
};

App.displayName = "App";

ReactDOM.render(
	<ErrorBoundary>
		<App />
	</ErrorBoundary>,
	document.getElementById("root"),
);
