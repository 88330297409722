import React from "react";
import styles from "./intro.scss";
import { Button } from "semantic-ui-react";
type Props = {
	acceptDisclaimer: () => void;
};
export const Disclaimer: React.FC<Props> = (props) => {
	return (
		<section className={styles.container}>
			<h1>Warning</h1>
			<ul>
				<li>
					<b style={{ color: "red" }}>
						Some of these images express vile sentiments
					</b>
					. There is all manner of objectionable content in them...racism,
					sexism, misogyny, anti-semitism, etc.
				</li>
				<li>
					These images are part of a data set provided by Facebook for an{" "}
					<a
						target="_blank"
						rel="noreferrer"
						href="https://www.drivendata.org/competitions/64/hateful-memes/"
					>
						AI contest
					</a>
					. I only included 100 here to meet the requirements of the data
					agreement.
				</li>
				<li>
					I collect your (anonymized) responses, which uses a cookie. I will
					only use this data for statistical or research purposes.
				</li>
				<li>All images are ©Getty Images. You agree not to reproduce them.</li>
			</ul>
			<Button primary size="big" onClick={() => props.acceptDisclaimer()}>
				I Consent
			</Button>
		</section>
	);
};
Disclaimer.displayName = "Disclaimer";
