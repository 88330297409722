import React, { useState, useCallback, useEffect } from "react";
import Meme from "./Meme";
import Guess from "./Guess";
import GuessResult from "./GuessResult";
import { GuessThisMeme } from "./GuessThisMeme";
import { GuessResultDisplay } from "./GuessResultDisplay";
import styles from "./game.scss";
type Props = {};
export const Game: React.FC<Props> = (_props) => {
	const [error, setError] = useState<Error>();
	if (error != null) {
		throw error;
	}

	const onError = useCallback((error: Error) => setError(error), []);

	const [memeDeck, setMemeDeck] = useState<Meme[]>();
	const [memeDeckCurrentIndex, setMemeDeckCurrentIndex] = useState(0);
	const [lastGuessResult, setLastGuessResult] = useState<GuessResult>();
	const [guessInFlight, setGuessInFlight] = useState(false);
	const [streak, setStreak] = useState(0);
	const [_resetStreak, setResetStreak] = useState(false);

	const fetchMemeDeck = async () => {
		const memeDeckFn = window.firebase
			.functions()
			.httpsCallable("get_meme_deck");
		const memeDeck = await memeDeckFn();
		setMemeDeck(memeDeck.data);
	};

	const guessOnMeme = useCallback(
		(guess: Guess, meme: Meme) => {
			if (guessInFlight || guess == null || meme == null) {
				return;
			}

			setResetStreak((shouldReset) => {
				if (shouldReset) {
					setStreak(0);
				}

				return false;
			});

			setGuessInFlight(true);
			setLastGuessResult(undefined);

			const guessFn = window.firebase
				.functions()
				.httpsCallable("guess_on_meme");
			guessFn({
				memeId: meme.id,
				guess: guess,
			}).then(
				(guessResult) => {
					const result = guessResult.data as GuessResult;
					setLastGuessResult(guessResult.data);
					if (result.guess === result.facebookCategorization) {
						setStreak((s) => s + 1);
					} else {
						setResetStreak(true);
					}
					setGuessInFlight(false);
				},
				(error) => onError(error),
			);

			setMemeDeckCurrentIndex((i) => i + 1);
		},
		[guessInFlight, onError],
	);

	useEffect(() => {
		fetchMemeDeck().catch((error) => onError(error));
	}, [onError]);

	const currentMeme = memeDeck?.[memeDeckCurrentIndex % memeDeck.length];
	const lastMeme =
		memeDeckCurrentIndex > 0
			? memeDeck?.[(memeDeckCurrentIndex - 1) % memeDeck.length]
			: undefined;
	const nextMeme = memeDeck?.[(memeDeckCurrentIndex + 1) % memeDeck.length];
	if (nextMeme) new Image().src = nextMeme.imageUrl;

	return (
		<div className={styles.container}>
			<div className={styles.guess}>
				<GuessThisMeme
					meme={currentMeme}
					guessOnMeme={guessOnMeme}
					guessInFlight={guessInFlight}
				/>
			</div>
			<div className={styles.result}>
				<GuessResultDisplay
					meme={lastMeme}
					result={lastGuessResult}
					streak={streak}
				/>
			</div>
		</div>
	);
};
Game.displayName = "Game";
