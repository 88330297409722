import React from "react";
import styles from "./intro.scss";
import { Button } from "semantic-ui-react";
type Props = {
	done: () => void;
};

export const HowToPlay: React.FC<Props> = (props) => {
	return (
		<section className={styles.container}>
			<h1>Hate Speech Definition</h1>
			<p>
				<a
					target="_blank"
					rel="noreferrer"
					href="https://www.drivendata.org/competitions/64/hateful-memes/"
				>
					Facebook AI
				</a>{" "}
				defines <b>Hate Speech</b> as:
			</p>
			<blockquote>
				A direct or indirect attack on people based on characteristics,
				including ethnicity, race, nationality, immigration status, religion,
				caste, sex, gender identity, sexual orientation, and disability or
				disease. We define attack as violent or dehumanizing (comparing people
				to non-human things, e.g. animals) speech, statements of inferiority,
				and calls for exclusion or segregation. Mocking hate crime is also
				considered hate speech.
			</blockquote>
			<Button primary size="big" onClick={() => props.done()}>
				Ok
			</Button>
		</section>
	);
};
HowToPlay.displayName = "HowToPlay";
