import React from "react";
import Meme from "./Meme";
import Guess from "./Guess";
import { Loader, Button } from "semantic-ui-react";
import HateLabel from "./HateLabel";
import styles from "./guessthismeme.scss";
type Props = {
	meme: Meme | undefined;
	guessOnMeme: (guess: Guess, meme: Meme) => void;
	guessInFlight: boolean;
};
export const GuessThisMeme: React.FC<Props> = (props) => {
	const buttonsDisabled = props.meme == null || props.guessInFlight;
	return (
		<div className={styles.container}>
			<div className={styles.cardholder}>
				<div className={styles.card}>
					{props.meme == null ? (
						<Loader active inline="centered" />
					) : (
						<img src={props.meme.imageUrl} />
					)}
				</div>
			</div>
			<div className={styles.buttonholder}>
				<Button.Group widths="2">
					<Button
						disabled={buttonsDisabled}
						onClick={() => {
							if (props.meme != null) {
								props.guessOnMeme({ label: HateLabel.HATEFUL }, props.meme);
							}
						}}
						color="red"
						size="big"
					>
						Hate Speech
					</Button>
					{/* <Button.Or /> */}
					<Button
						disabled={buttonsDisabled}
						onClick={() => {
							if (props.meme != null) {
								props.guessOnMeme({ label: HateLabel.NOT_HATEFUL }, props.meme);
							}
						}}
						color="green"
						size="big"
					>
						Not Hate Speech
					</Button>
				</Button.Group>
			</div>
		</div>
	);
};
GuessThisMeme.displayName = "GuessThisMeme";
