import React, { Component } from "react";
import * as Sentry from "@sentry/browser";
import { Message, Button } from "semantic-ui-react";

type ErrorProps = {};
type ErrorState = {
	eventId?: string;
	hasError: boolean;
	errorTitle?: string;
	errorMessage?: string;
};

export class ErrorBoundary extends Component<ErrorProps, ErrorState> {
	constructor(props: ErrorProps) {
		super(props);
		this.state = {
			hasError: false,
		};
	}

	static getDerivedStateFromError(_error: unknown): Partial<ErrorState> {
		return {
			hasError: true,
		};
	}

	componentDidCatch(error: Error, errorInfo: { componentStack: string }): void {
		Sentry.withScope((scope) => {
			scope.setExtras(errorInfo);
			const eventId = Sentry.captureException(error);
			this.setState({ eventId });
		});
	}

	render(): React.ReactNode {
		if (this.state.hasError) {
			const errorButton =
				this.state.eventId == null ? (
					""
				) : (
					<Button
						primary
						onClick={(): void =>
							Sentry.showReportDialog({
								eventId: this.state.eventId,
							})
						}
					>
						Help!
					</Button>
				);

			return (
				<Message negative>
					<Message.Header>
						{this.state.errorTitle ?? "We ran into an error!"}
					</Message.Header>
					<p>
						{this.state.errorMessage ??
							`Please let me know a bit about what went wrong so I can fix it.
						Afterwards, try refreshing the page.`}
					</p>
					{errorButton}
				</Message>
			);
		}

		//when there's not an error, render children untouched
		return this.props.children;
	}
}
