import React from "react";
import * as Sentry from "@sentry/browser";

export const SentryProvider: React.FC = (props) => {
	const auth = window.firebase.auth();

	React.useEffect(() => {
		return auth.onAuthStateChanged((user) => {
			Sentry.configureScope((scope) => scope.setUser({ id: user?.uid }));
		});
	}, [auth]);

	return <>{props.children}</>;
};

SentryProvider.displayName = "SentryProvider";
