import React from "react";
import GuessResult from "./GuessResult";
import Meme from "./Meme";
import { Loader, Button, Icon } from "semantic-ui-react";
import styles from "./guessresultdisplay.scss";
import HateLabel from "./HateLabel";
type Props = {
	meme: Meme | undefined;
	result: GuessResult | undefined;
	streak: number;
};
export const GuessResultDisplay: React.FC<Props> = (props) => {
	if (props.meme == null) {
		// empty, no guess yet
		return <div className={styles.container}></div>;
	}

	if (props.result == null) {
		return (
			<div className={styles.container}>
				<Loader active inline="centered" />
			</div>
		);
	}

	let header, subheader, action;
	const guess = props.result.guess;
	const facebookCategorization = props.result.facebookCategorization;

	if (guess === facebookCategorization) {
		header = "✅";
		subheader = "Facebook AI agrees.";
		action = <h3>Current Streak: {props.streak}</h3>;
	} else {
		header = "❌";
		if (guess === HateLabel.NOT_HATEFUL) {
			subheader = "Facebook AI deems this hate speech.";
		} else {
			subheader = "Facebook AI doesn't deem this hate speech.";
		}
		action = (
			<Button
				color="twitter"
				as="a"
				href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
					`Can you beat ${props.streak} in a row on IsItHate.com? #IsItHate`,
				)}`}
				target="_blank"
				rel="noreferrer"
			>
				<Icon name="twitter" /> #IsItHate
			</Button>
		);
	}

	return (
		<div className={styles.container}>
			<div className={styles.box}>
				<div className={styles.text}>
					<h1>{header}</h1>
					<h3>{subheader}</h3>
					{action}
				</div>
				<div className={styles.meme}>
					<img src={props.meme.imageUrl} />
				</div>
			</div>
		</div>
	);
};
GuessResultDisplay.displayName = "GuessResultDisplay";
